@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

$granite-900: #212121;
$granite-800: #424242;
$granite-600: #757575;
$granite-400: #bdbdbd;
$granite-300: #e0e0e0;
$grass-600: #43A047;
$orange-800: #ef6c00;

// $title-size: 3rem;
$title-color: $granite-900;
$title-line-height: 2.5rem;
$subtitle-color: $granite-600;
$title-weight: 400;

$primary: $grass-600;
$warning: $orange-800;
$footer-background-color: $granite-400;

$navbar-item-img-max-height: 4rem;
$navbar-height: 5.5rem;
$body-background-color: #f7f8fa;
$card-background-color: #FBFCFC;

.sub-container {
  background-color: $granite-900;
  // min-height: 4rem;
  height: available;
}

.navbar {
  margin-bottom: 4rem;
}

.navbar-item {
  margin-left: 1.5rem;
}

.h1 {
  margin-bottom: 2rem;
}

.footer {
  margin-top: 4rem;
}

.frontpage-search {
  margin-top: 4rem;
}

.notification {
  margin-top: 4rem;
}

.field {
  margin-top: 1.2rem;
}

.error-container {
  min-height: 10rem;
}

@import "./node_modules/bulma/sass/utilities/_all.sass";
@import "./node_modules/bulma/sass/helpers/_all.sass";
@import "./node_modules/bulma/sass/base/_all.sass";
@import "./node_modules/bulma/sass/elements/_all.sass";
@import "./node_modules/bulma/sass/form/_all.sass";
@import "./node_modules/bulma/sass/components/_all.sass";
@import "./node_modules/bulma/sass/layout/_all.sass";
@import "./node_modules/bulma/sass/grid/_all.sass";
